import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Search from '@/components/layout/Search/search.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import fab from 'vue-fab'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import MessageSmsRepository from '@/shared/http/repositories/socialProject/message-sms'
import MessageSmsModel from '@/shared/models/messageSms'

export default {
  name: 'MessageSms',

  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search,
    fab
  },

  data: () => ({
		messages: [],
		labelSearch: 'Cadastro de Mensagens SMS',
		emptyText: 'Não há mensagens cadastradas.',
    showModalDeleteMessage: false,
    MessageSmsModel: new MessageSmsModel(),
    fields: [
      {
        key: 'title',
        label: 'TITULO DA MENSAGEM',
        class: 'left-header-border text-center'
      },
      {
        key: 'message',
        label: 'TEXTO DA MENSAGEM',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    },
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Cadastro de Mensagem SMS',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAll()
  },

  methods: {
    getAll() {
      loading.push()
      MessageSmsRepository.GetAll(this.MessageSmsModel, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.messages = res.data.data
          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar mensagens', 'ERRO')
        })
    },

    search(searchText) {
      if (searchText != null) {
        this.MessageSmsModel.title = searchText
        this.getAll()
      }
    },

    selectRow(id) {
      this.showModalDeleteMessage = true
      this.id = id
    },

    onDelete() {
      loading.push()
      MessageSmsRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteMessage = false
          toast.success('Mensagem excluída com sucesso!', 'EXCLUSÃO DE MENSAGEM')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir mensagem', 'ERRO')
        })
    },

    toast(data, info) {
      this.$bvToast.toast(`${data.message}`, {
        title: `${data.title}`,
        toaster: 'b-toaster-top-full',
        solid: true,
        variant: info
      })
    }
  }
}
