import GuidUtils from '@/shared/utils/guid'

export default class MessageSms {
  constructor({
    id,
    title,
    message,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.title = title,
    this.message = message
  }
}